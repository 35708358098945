import Vue from "vue";
import Vuex from "vuex";
import dict from "./modules/dict";
import breadcrumb from "./modules/breadcrumb";
import user from "./modules/user";
import getters from "./getters";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    dict,
    breadcrumb,
    user,
  },
  getters,
});

export default store;
