<template>
  <div class="main">
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <el-card class="box-card" v-if="$route.meta.showCard">
      <h2><span></span>{{ $route.meta.cardTitle }}</h2>
      <router-view />
    </el-card>
    <router-view v-else />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
::v-deep .box-card {
  min-height: 70vh;
  margin-top: 20px;
  border-radius: 4px;
  box-shadow: none;
  border: none;
  .el-card__body {
    padding: 20px;
    h2 {
      color: #333333;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      span {
        display: block;
        width: 4px;
        height: 16px;
        background: #5e83f5;
        margin-right: 8px;
      }
    }
  }
}
</style>
