import request from "@/utils/request";
// 登录
const login = (params) => request.post("/boxing/web/admin/webLogin", params);
// 退出登录
// const loginOut = () => request.post("/apc/account/exit");
// 数据字典
const getDictCode = (params) =>
  request.post("/boxing/systemSet/queryListByDictCode", params);
// 协议列表
const getAgreementList = (params) =>
  request.post("/boxing/systemSet/pageAgreement", params);
// 添加协议
const handleAgreement = (params) =>
  request.post("/boxing/systemSet/addAgreement", params);
// 修改协议
const editAgreement = (params) =>
  request.post("/boxing/systemSet/modifyAgreement", params);
// oss上传
const uploadOss = (params) =>
  request.post("/boxing/common/uploadFile", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
// 删除协议
const deleteAgreement = (params) =>
  request.post("/boxing/systemSet/deleteAgreement", params);
// 根据名称查询协议信息
const getInfo = (params) =>
  request.post("/boxing/systemSet/selectAgreement", params);
// 获取用户列表
const getUserList = (params) =>
  request.post("/boxing/web/user/pageUserList", params);
// 修改用户状态
const editUserStatus = (params) =>
  request.post("/boxing/web/user/modifyUserStatus", params);
const getBindList = (params) =>
  request.post(
    "/boxing/web/raffleActivity/pageRaffleActivityBindRecord",
    params
  );
const editBindInfo = (params) =>
  request.post("/boxing/web/raffleActivity/alterRaffleActivityBind", params);
// 注销账户列表
const getLogoutList = (params) =>
  request.post("/boxing/web/user/logoutAccountUserList", params);
// 注销用户
const setLogout = (params) =>
  request.post("/boxing/web/user/logoutAccount", params);
export {
  login,
  getDictCode,
  getAgreementList,
  handleAgreement,
  uploadOss,
  deleteAgreement,
  getInfo,
  editAgreement,
  getUserList,
  editUserStatus,
  getBindList,
  editBindInfo,
  getLogoutList,
  setLogout,
};
