import { getDictCode } from "@/api/login";

const state = {
  /**
   * 数据字典 MAP
   * key：数据字典大类枚举值 dictType
   * dictValue：数据字典小类数值 {dictValue: '', dictLabel: ''} 的数组
   */
  dictDatas: {},
};

const mutations = {
  SET_DICT_DATAS: (state, dictDatas) => {
    // console.log("dictDatas :>> ", dictDatas);
    state.dictDatas[dictDatas.dictCode] = dictDatas.dictDataMap;
  },
};

const actions = {
  loadDictDatas({ commit, state }, dictCode) {
    // console.log("dictCode :>> ", dictCode);
    // console.log(state.dictDatas[dictCode]);
    if (state.dictDatas[dictCode]) return;
    getDictCode({ dictCode }).then((response) => {
      // 如果未加载到数据，则直接返回
      if (!response || !response.result) {
        return;
      }
      // 设置数据
      let dictDataMap = [];
      response.result.forEach((dictData) => {
        dictDataMap.push({
          value: Number(dictData.itemValue),
          label: dictData.itemText,
          description: dictData.description,
        });
      });
      const result = {
        dictCode,
        dictDataMap,
      };
      // 存储到 Store 中
      commit("SET_DICT_DATAS", result);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
