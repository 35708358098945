import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/* Layout */
import Layout from "@/layout";

// 公共路由
export const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/views/Login"),
    meta: { title: "登录" },
    hidden: true,
  },
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    hidden: true,
    children: [
      {
        path: "/home",
        component: () => import("@/views/Home"),
        name: "home",
        hidden: false,
        meta: {
          title: "首页",
          showCard: true,
          icon: require("@/assets/tarbar/home.png"),
          activeicon: require("@/assets/tarbar/home_active.png"),
        },
      },
      {
        path: "/club",
        component: () => import("@/views/Club"),
        hidden: false,
        name: "club",
        redirect: "noRedirect",
        meta: {
          title: "俱乐部管理",
          icon: require("@/assets/tarbar/club.png"),
          activeicon: require("@/assets/tarbar/club_active.png"),
          showCard: true,
        },
        children: [
          {
            path: "/fightclub",
            component: () => import("@/views/Club/FightClub"),
            hidden: false,
            name: "fightclub",
            meta: {
              title: "俱乐部列表",
              showCard: true,
              cardTitle: "俱乐部列表",
              parent: "club",
            },
          },
          {
            path: "/auditList",
            component: () => import("@/views/Club/AuditList"),
            hidden: false,
            name: "auditList",
            meta: {
              title: "审批列表",
              showCard: true,
              cardTitle: "审批列表",
              parent: "club",
            },
          },
          {
            path: "/modifyList",
            component: () => import("@/views/Club/ModifyList"),
            hidden: false,
            name: "modifyList",
            meta: {
              title: "俱乐部信息变更列表",
              showCard: true,
              cardTitle: "俱乐部信息变更列表",
              parent: "club",
            },
          },
          {
            path: "/clubdetail",
            component: () => import("@/views/Club/ClubDetail"),
            hidden: true,
            name: "clubdetail",
            meta: {
              title: "俱乐部详情",
              showCard: false,
              parent: "club",
              parentPath: "/fightclub",
              parentName: "俱乐部列表",
            },
          },
          {
            path: "/modifydetail",
            component: () => import("@/views/Club/ModifyDetail"),
            hidden: true,
            name: "modifydetail",
            meta: {
              title: "俱乐部信息变更详情",
              showCard: false,
              parent: "club",
              parentPath: "/modifyList",
              parentName: "俱乐部信息变更列表",
            },
          },
        ],
      },
      {
        path: "/course",
        component: () => import("@/views/Course"),
        hidden: false,
        name: "course",
        redirect: "/courselist",
        meta: {
          title: "课程管理",
          icon: require("@/assets/tarbar/course.png"),
          activeicon: require("@/assets/tarbar/course_active.png"),
          showCard: true,
        },
        children: [
          {
            path: "/courselist",
            component: () => import("@/views/Course/CourseList"),
            hidden: false,
            name: "courselist",
            meta: {
              title: "课程列表",
              showCard: true,
              cardTitle: "课程列表",
              parent: "course",
            },
          },
          {
            path: "/checkList",
            component: () => import("@/views/Course/CheckList"),
            hidden: false,
            name: "checkList",
            meta: {
              title: "新上课程审核",
              showCard: true,
              cardTitle: "新上课程审核",
              parent: "course",
            },
          },
          {
            path: "/alterationList",
            component: () => import("@/views/Course/AlterationList"),
            hidden: false,
            name: "alterationList",
            meta: {
              title: "课程信息变更审核",
              showCard: true,
              cardTitle: "课程信息变更审核",
              parent: "course",
            },
          },
          {
            path: "/coursedetail",
            component: () => import("@/views/Course/CourseDetail"),
            hidden: true,
            name: "coursedetail",
            meta: {
              title: "课程详情",
              showCard: false,
              parent: "courselist",
              parentPath: "/courselist",
              parentName: "课程列表",
            },
          },
          {
            path: "/alterationdetail",
            component: () => import("@/views/Course/AlterationDetail"),
            hidden: true,
            name: "alterationdetail",
            meta: {
              title: "课程信息变更详情",
              showCard: false,
              parent: "alterationList",
              parentPath: "/alterationList",
              parentName: "课程信息变更审核",
            },
          },
        ],
      },
      {
        path: "/video",
        component: () => import("@/views/Video"),
        hidden: false,
        name: "video",
        redirect: "noRedirect",
        meta: {
          title: "视频管理",
          icon: require("@/assets/tarbar/video.png"),
          activeicon: require("@/assets/tarbar/video_active.png"),
          showCard: true,
        },
        children: [
          {
            path: "/videolist",
            component: () => import("@/views/Video/VideoList"),
            hidden: false,
            name: "videolist",
            meta: {
              title: "视频列表",
              showCard: true,
              cardTitle: "视频列表",
              parent: "video",
            },
          },
          {
            path: "/reviewList",
            component: () => import("@/views/Video/ReviewList"),
            hidden: false,
            name: "reviewList",
            meta: {
              title: "视频审核",
              showCard: true,
              cardTitle: "视频审核",
              parent: "video",
            },
          },
          {
            path: "/commentList",
            component: () => import("@/views/Video/CommentList"),
            hidden: false,
            name: "commentList",
            meta: {
              title: "评论审核",
              showCard: true,
              cardTitle: "评论审核",
              parent: "video",
            },
          },
        ],
      },
      {
        path: "/game",
        component: () => import("@/views/Game"),
        hidden: false,
        name: "game",
        redirect: "noRedirect",
        meta: {
          title: "游戏管理",
          icon: require("@/assets/tarbar/game.png"),
          activeicon: require("@/assets/tarbar/game_active.png"),
          showCard: true,
          breadcrumb: false,
        },
        children: [
          {
            path: "/rhythmlist",
            component: () => import("@/views/Game/RhythmList"),
            hidden: false,
            name: "rhythmlist",
            meta: {
              title: "节奏拳击",
              showCard: true,
              cardTitle: "节奏拳击",
              parent: "game",
            },
          },
          {
            path: "/addMusic",
            component: () => import("@/views/Game/AddMusic"),
            hidden: true,
            name: "addMusic",
            meta: {
              title: "新增音乐",
              showCard: true,
              cardTitle: "新增音乐",
              parent: "rhythmlist",
              parentPath: "/rhythmlist",
              parentName: "节奏拳击",
            },
          },
          {
            path: "/editMusic",
            component: () => import("@/views/Game/EditMusic"),
            hidden: true,
            name: "editMusic",
            meta: {
              title: "修改音乐",
              showCard: true,
              cardTitle: "修改音乐",
              parent: "rhythmlist",
              parentPath: "/rhythmlist",
              parentName: "节奏拳击",
            },
          },
        ],
      },
      {
        path: "/shop",
        component: () => import("@/views/Shop"),
        hidden: false,
        name: "shop",
        redirect: "noRedirect",
        meta: {
          title: "商城管理",
          icon: require("@/assets/tarbar/shop.png"),
          activeicon: require("@/assets/tarbar/shop_active.png"),
          showCard: true,
          breadcrumb: false,
        },
        children: [
          // {
          //   path: "/goods",
          //   component: () => import("@/views/Shop/GoodsList"),
          //   hidden: false,
          //   name: "goods",
          //   meta: {
          //     title: "商品列表",
          //     showCard: true,
          //     cardTitle: "商品列表",
          //     parent: "shop",
          //   },
          // },
          {
            path: "/addGoods",
            component: () => import("@/views/Shop/AddGoods"),
            hidden: true,
            name: "addGoods",
            meta: {
              title: "新增商品",
              showCard: true,
              cardTitle: "新增商品",
              parent: "order",
              parentPath: "/order",
              parentName: "订单列表",
            },
          },
          {
            path: "/order",
            component: () => import("@/views/Shop/OrderList"),
            hidden: false,
            name: "order",
            meta: {
              title: "订单列表",
              showCard: true,
              cardTitle: "订单列表",
              parent: "shop",
            },
          },
          {
            path: "/agent",
            component: () => import("@/views/Shop/AgentList"),
            hidden: false,
            name: "agent",
            meta: {
              title: "代理商列表",
              showCard: true,
              cardTitle: "代理商列表",
              parent: "shop",
            },
          },
          {
            path: "/brokerage",
            component: () => import("@/views/Shop/BrokerageList"),
            hidden: false,
            name: "brokerage",
            meta: {
              title: "代理佣金",
              showCard: true,
              cardTitle: "代理佣金",
              parent: "shop",
            },
          },
        ],
      },
      {
        path: "/customer",
        component: () => import("@/views/Customer"),
        hidden: false,
        name: "customer",
        redirect: "/customerlist",
        meta: {
          title: "用户管理",
          icon: require("@/assets/tarbar/customer.png"),
          activeicon: require("@/assets/tarbar/customer_active.png"),
          showCard: true,
          breadcrumb: false,
        },
        children: [
          {
            path: "/customerlist",
            component: () => import("@/views/Customer/CustomerList"),
            hidden: false,
            name: "customerlist",
            meta: {
              title: "用户列表",
              showCard: true,
              cardTitle: "用户列表",
              parent: "customer",
            },
          },
          {
            path: "/logoutList",
            component: () => import("@/views/Customer/LogoutList"),
            hidden: false,
            name: "logoutList",
            meta: {
              title: "注销账户",
              showCard: true,
              cardTitle: "注销账户",
              parent: "customer",
            },
          },
        ],
      },
      {
        path: "/setting",
        component: () => import("@/views/Setting"),
        hidden: false,
        name: "setting",
        redirect: "/agreement",
        meta: {
          title: "系统设置",
          icon: require("@/assets/tarbar/setting.png"),
          activeicon: require("@/assets/tarbar/setting_active.png"),
          showCard: true,
        },
        children: [
          {
            path: "/agreement",
            component: () => import("@/views/Setting/Agreement"),
            hidden: false,
            name: "agreement",
            meta: {
              title: "协议列表",
              showCard: true,
              cardTitle: "协议列表",
              parent: "setting",
            },
            redirect: "/agreementlist",
            children: [
              {
                path: "/agreementlist",
                component: () =>
                  import("@/views/Setting/Agreement/AgreementList"),
                hidden: true,
                name: "agreementlist",
                meta: {
                  title: "协议列表",
                  showCard: true,
                  parent: "setting",
                  cardTitle: "协议列表",
                  breadcrumb: false,
                },
              },
              {
                path: "/newagreement",
                component: () =>
                  import("@/views/Setting/Agreement/NewAgreement"),
                hidden: true,
                name: "newagreement",
                meta: {
                  title: "协议",
                  showCard: true,
                  parent: "setting",
                  cardTitle: "协议",
                },
              },
            ],
          },
          {
            path: "/news",
            component: () => import("@/views/Setting/News"),
            hidden: false,
            name: "news",
            meta: {
              title: "资讯列表",
              showCard: true,
              cardTitle: "资讯列表",
              parent: "setting",
            },
            redirect: "/newslist",
            children: [
              {
                path: "/newslist",
                component: () => import("@/views/Setting/News/NewsList"),
                hidden: true,
                name: "newslist",
                meta: {
                  title: "资讯列表",
                  showCard: true,
                  parent: "setting",
                  cardTitle: "资讯列表",
                  breadcrumb: false,
                },
              },
              {
                path: "/addNews",
                component: () => import("@/views/Setting/News/AddNews"),
                hidden: true,
                name: "addNews",
                meta: {
                  title: "添加资讯",
                  showCard: true,
                  parent: "setting",
                  cardTitle: "添加资讯",
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

export default new Router({
  mode: "history", // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});
