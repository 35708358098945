<template>
  <div class="layout">
    <el-container>
      <el-header>
        <lay-header />
      </el-header>
      <el-container>
        <el-aside>
          <lay-nav />
        </el-aside>
        <el-main>
          <lay-main />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import LayHeader from "./AppHeader.vue";
import LayNav from "./Sidebar";
import LayMain from "./AppMain.vue";
export default {
  components: { LayHeader, LayNav, LayMain },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.layout {
  .el-header {
    background: #ffffff;
    height: 71px !important;
    padding: 0;
  }
  ::v-deep .el-aside {
    height: calc(100vh - 71px);
    width: 267px !important;
    padding: 12px 4px;
    box-sizing: border-box;
    background: #fff;
    > div {
      height: 100%;
    }
    .el-menu {
      border: none !important;
      > div {
        margin-bottom: 4px;
        > .is-active {
          background: none;
        }
      }
      .el-menu-item {
        padding: 9px 16px !important;
        display: flex;
        align-items: center;
        // justify-content: space-around;
        > span {
          line-height: 22px;
          margin-left: 16px;
          color: #4e5969;
        }
        .iconfont {
          margin: 0;
          display: block;
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
          }
          // background: #333;
        }
      }
      .is-active {
        background: rgba(94, 131, 245, 0.16);
        > span {
          color: #5e83f5;
        }
      }
      .is-active {
        .el-menu-item:hover {
          background: rgba(94, 131, 245, 0.16);
        }
      }
      .el-menu-item:hover {
        background: none;
        color: #5e83f5;
        > span {
          color: #5e83f5;
        }
      }
      .el-submenu {
        .el-submenu__title {
          padding: 9px 16px !important;
          display: flex;
          align-items: center;
          > span {
            line-height: 22px;
            margin-left: 16px;
            color: #4e5969;
          }
          .iconfont {
            margin: 0;
            display: block;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              height: 100%;
            }
            // background: #333;
          }

          .el-submenu__icon-arrow::before {
            font-size: 14px;
            font-weight: 800;
          }
        }
        .el-submenu__title:hover {
          background: none;
          > span {
            color: #5e83f5;
          }
          .el-submenu__icon-arrow::before {
            color: #5e83f5;
          }
        }
        .el-menu-item {
          padding-left: 49px !important;
          span {
            margin: 0;
          }
        }
      }
      .is-opened {
        .el-submenu__title {
          background: none;
          > span {
            color: #5e83f5;
          }
          .el-submenu__icon-arrow::before {
            color: #5e83f5;
            font-size: 14px;
            font-weight: 800;
          }
        }
      }
    }
    // background: #001529;
  }
  .el-main {
    padding: 17px 20px 2px;
    background: #f7f8fa;
    height: calc(100vh - 80px);
  }
  .el-main::-webkit-scrollbar {
    overflow: hidden;
  }
  ::v-deep .el-aside::-webkit-scrollbar {
    overflow: hidden;
  }
}
</style>
