import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./style/main.scss";
import "lib-flexible";
import "./permission";
import {
  DICT_TYPE,
  getDictDataLabel,
  getDictDatas,
  getDictDatas2,
} from "@/utils/dict";
import validator from "@/utils/validator";
import Pagination from "@/components/Pagination";
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.getDictDatas = getDictDatas;
Vue.prototype.getDictDatas2 = getDictDatas2;
Vue.prototype.getDictDataLabel = getDictDataLabel;
Vue.prototype.validator = validator;
Vue.prototype.$moment = moment;
Vue.prototype.DICT_TYPE = DICT_TYPE;
Vue.component("Pagination", Pagination);
ElementUI.Dialog.props.lockScroll.default = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
