<template>
  <div class="header">
    <div class="left">
      <p><img src="../assets/logo.png" alt="" /></p>
      <span>天天拳击</span>
    </div>
    <!-- <ul>
      <li>1</li>
      <li>1</li>
      <li>1</li>
      <li>1</li>
      <li>1</li>
    </ul> -->
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link">
        <!-- <i class="el-icon-s-unfold" style="cursor: pointer"></i> -->
        退出登录
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    // 退出登录
    handleCommand() {
      this.$confirm("确定退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("LogOut").then(() => {
            // window.sessionStorage.setItem("activerouter", '/quality')
            location.href = "/login";
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  background: #ffffff;
  box-shadow: 0px 3px 13px 0px rgba(94, 131, 245, 0.1);
  padding: 0 22px 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    p {
      width: 80px;
      height: 55px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    span {
      display: block;
      margin-left: 8px;
      font-size: 25px;
      color: #5e83f5;
      line-height: 34px;
      font-weight: bold;
    }
  }
  ul {
    display: flex;
    li {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 1px solid #333;
      margin-right: 16px;
    }
  }
}
</style>
