import router from "./router";
import store from "./store";
// import { Message } from "element-ui";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getToken } from "@/utils/auth";
// import { isRelogin } from "@/utils/request";

NProgress.configure({ showSpinner: false });

const whiteList = ["/login"];

router.beforeEach((to, from, next) => {
  document.title = "天天拳击";
  NProgress.start();
  if (getToken()) {
    // to.meta.title && store.dispatch("settings/setTitle", to.meta.title);
    /* has token*/
    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done();
    } else {
      // console.log("to :>> ", to);
      if (to.meta.parent === "course") {
        store.dispatch("dict/loadDictDatas", "course_review_status");
        store.dispatch("dict/loadDictDatas", "course_category");
        store.dispatch("dict/loadDictDatas", "coach_status");
        store.dispatch("dict/loadDictDatas", "modify_status");
      }
      if (to.meta.parent === "video") {
        store.dispatch("dict/loadDictDatas", "content_review_status");
      }
      if (to.meta.parent === "shop") {
        store.dispatch("dict/loadDictDatas", "goods_order_status");
        store.dispatch("dict/loadDictDatas", "sale_agent_commission_source");
        store.dispatch("dict/loadDictDatas", "commission_status");
        store.dispatch("dict/loadDictDatas", "show_status");
      }
      // if (store.getters.roles.length === 0) {
      //   isRelogin.show = true;
      //   // 获取字典数据 add by 芋艿
      //   store.dispatch("dict/loadDictDatas");
      //   // 判断当前用户是否已拉取完 user_info 信息
      //   store
      //     .dispatch("GetInfo")
      //     .then((userInfo) => {
      //       isRelogin.show = false;
      //       // 触发 GenerateRoutes 事件时，将 menus 菜单树传递进去
      //       store
      //         .dispatch("GenerateRoutes", userInfo.menus)
      //         .then((accessRoutes) => {
      //           // 根据 roles 权限生成可访问的路由表
      //           router.addRoutes(accessRoutes); // 动态添加可访问路由表
      //           next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
      //         });
      //     })
      //     .catch((err) => {
      //       store.dispatch("LogOut").then(() => {
      //         Message.error(err);
      //         next({ path: "/" });
      //       });
      //     });
      // } else {

      next();
      // }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      const redirect = encodeURIComponent(to.fullPath); // 编码 URI，保证参数跳转回去后，可以继续带上
      next(`/login?redirect=${redirect}`); // 否则全部重定向到登录页
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
