/**
 * Created by 芋道源码
 *
 * 数据字典工具类
 */
import store from "@/store";

export const DICT_TYPE = {
  CLUB_STATUS: "club_status", // 俱乐部状态
  CLUB_MODIFY_STATUS: "club_modify_status", //俱乐部信息变更状态
  COURSE_REVIEW_STATUS: "course_review_status", //俱乐部课程状态
  COURSE_CATEGORY: "course_category", //课程类型
  COACH_STATUS: "coach_status", //教练授课状态
  MODIFY_STATUS: "modify_status", //课程修改状态
  CONTENT_REVIEW_STATUS: "content_review_status", //视频评论状态
  GOODS_ORDER_STATUS: "goods_order_status", //商品订单状态
  SALE_AGENT_COMMISSION_SOURCE: "sale_agent_commission_source", //佣金来源
  COMMISSION_STATUS: "commission_status", //佣金结算状态
  SHOW_STATUS: "show_status", //佣金显示状态
};

/**
 * 获取 dictType 对应的数据字典数组
 *
 * @param dictType 数据类型
 * @returns {*|Array} 数据字典数组
 */
export function getDictDatas(dictType) {
  return store.getters.dict_datas[dictType] || [];
}

/**
 * 获取 dictType 对应的数据字典数组
 *
 * @param dictType 数据类型
 * @param values 数组、单个元素
 * @returns {*|Array} 数据字典数组
 */
export function getDictDatas2(dictType, values) {
  if (values === undefined) {
    return [];
  }
  // 如果是单个元素，则转换成数组
  if (!Array.isArray(values)) {
    values = [this.value];
  }
  // 获得字典数据
  const results = [];
  for (const value of values) {
    const dict = getDictData(dictType, value);
    if (dict) {
      results.push(dict);
    }
  }
  return results;
}

export function getDictData(dictType, value) {
  // 获取 dictType 对应的数据字典数组
  const dictDatas = getDictDatas(dictType);
  if (!dictDatas || dictDatas.length === 0) {
    return "";
  }
  // 获取 value 对应的展示名
  // value = value + ""; // 强制转换成字符串，因为 DictData 小类数值，是字符串

  for (const dictData of dictDatas) {
    if (dictData.value === value) {
      return dictData;
    }
  }
  return undefined;
}

export function getDictDataLabel(dictType, value) {
  const dict = getDictData(dictType, value);
  // console.log("dict :>> ", value);
  return dict ? dict.label : "";
}
