// 面包屑不是路由的相关渲染
const state = {
  breadcrumbData: {},
};

const mutations = {
  SET_BREAD_DATAS: (state, breadDatas) => {
    state.breadcrumbData = breadDatas;
  },
};

const actions = {
  getBreadDatas({ commit }, data) {
    // 存储到 Store 中
    commit("SET_BREAD_DATAS", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
