const validator = {
  // 价格校验
  moreThanZero(rules, value, callback) {
    var reg = new RegExp(/^([1-9]\d*|0)(\.\d{1,3})?$/);
    // if (!value) {
    //   return callback(new Error("请输入"));
    // } else {
    if (value && !reg.test(value)) {
      callback(new Error("请输入大于等于0的数字，并且最多3位小数"));
    } else {
      callback();
    }
    // }
  },
  // 用户名验证
  username(rules, value, callback) {
    var reg = new RegExp(/^[\u4e00-\u9fa5_a-zA-Z0-9]{1,30}$/);
    if (!value) {
      return callback(new Error("用户名不能为空"));
    } else {
      if (value && !reg.test(value)) {
        callback(
          new Error("用户名不能包含空格和特殊字符，且在1到30个字符之间")
        );
      } else {
        callback();
      }
    }
  },
  // 密码验证
  password(rules, value, callback) {
    var reg = new RegExp(
      /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{9,}/,
      "g"
    );
    const regs = new RegExp(/\s/g);
    if (!value) {
      return callback(new Error("密码不能为空"));
    } else {
      if (regs.test(value) || !reg.test(value)) {
        callback(
          new Error(
            "请输入9位以上的大小写字母、数字，以及特殊字符混合表达式，且不能输入空格"
          )
        );
      } else {
        callback();
      }
    }
  },
  // 输入框字数验证
  inputValidator(rule, value, callback) {
    // console.log(value, Number)
    const len = value.replace(/[^\x00-\xff]/g, 'aa').length // eslint-disable-line
    if (len > 200) {
      callback(new Error("最多可填写200个字符"));
    } else {
      callback();
    }
  },
  // 邮箱验证
  email(rule, value, callback) {
    var reg = new RegExp(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]{2,6})+$/)// eslint-disable-line
    if (!value) {
      return callback(new Error("邮箱不能为空！"));
    } else {
      if (value && !reg.test(value)) {
        callback(new Error("请输入正确邮箱！"));
      } else {
        callback();
      }
    }
  },
  // 手机号验证
  phone(rule, value, callback) {
    if (!value) {
      return callback(new Error("请输入联系电话"));
    } else {
      if (
        value &&
        !/^((0\d{2,3}-\d{7,8})|(1(3|4|5|6|7|8|9)\d{9}))$/.test(value)
      ) {
        callback(new Error("请输入正确的联系电话"));
        return false;
      } else {
        callback();
      }
    }
  },
  // 身份证号校验
  validateIdCard(rule, sId, callback) {
    if (!/(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(sId)) {
      callback(new Error("你输入的身份证长度或格式错误"));
      return false;
    }
    //身份证城市
    var aCity = {
      11: "北京",
      12: "天津",
      13: "河北",
      14: "山西",
      15: "内蒙古",
      21: "辽宁",
      22: "吉林",
      23: "黑龙江",
      31: "上海",
      32: "江苏",
      33: "浙江",
      34: "安徽",
      35: "福建",
      36: "江西",
      37: "山东",
      41: "河南",
      42: "湖北",
      43: "湖南",
      44: "广东",
      45: "广西",
      46: "海南",
      50: "重庆",
      51: "四川",
      52: "贵州",
      53: "云南",
      54: "西藏",
      61: "陕西",
      62: "甘肃",
      63: "青海",
      64: "宁夏",
      65: "新疆",
      71: "台湾",
      81: "香港",
      82: "澳门",
      91: "国外",
    };
    if (!aCity[parseInt(sId.substr(0, 2))]) {
      callback(new Error("你的身份证地区非法"));
      return false;
    }

    // 出生日期验证
    var sBirthday = (
        sId.substr(6, 4) +
        "-" +
        Number(sId.substr(10, 2)) +
        "-" +
        Number(sId.substr(12, 2))
      ).replace(/-/g, "/"),
      d = new Date(sBirthday);
    if (
      sBirthday !=
      d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate()
    ) {
      callback(new Error("身份证上的出生日期非法"));
      return false;
    }

    // 身份证号码校验
    var sum = 0,
      weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
      codes = "10X98765432";
    for (var i = 0; i < sId.length - 1; i++) {
      sum += sId[i] * weights[i];
    }
    var last = codes[sum % 11]; //计算出来的最后一位身份证号码
    if (sId[sId.length - 1] != last) {
      callback(new Error("你输入的身份证号非法"));
      return false;
    }

    callback();
  },
  // 行政区code验证
  areaCode(rule, value, callback) {
    var reg = new RegExp(/^[1-8][0-7]\d{4}$/);
    if (!value) {
      return callback(new Error("请输入行政区号"));
    }
    if (value && !reg.test(value)) {
      callback(new Error("请输入正确行政区号！"));
    } else {
      callback();
    }
  },
  // 出生日期验证
  Birthdate(rule, value, callback) {
    var reg = new RegExp(
      /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/
    );
    if (!value) {
      return callback(new Error("请输入出生日期"));
    }
    if (value && reg.test(value)) {
      var year = value.substring(0, 4);
      var month = value.substring(4, 6);
      var date = value.substring(6, 8);
      var date2 = new Date(year + "-" + month + "-" + date);
      if (date2 && date2.getMonth() == parseInt(month) - 1) {
        callback();
      } else {
        callback(new Error("请输入正确出生日期 例19900101"));
      }
    } else {
      callback(new Error("请输入正确出生日期 例19900101"));
    }
  },
  // 检测特殊字符 可以输入-
  string(rule, value, callback) {
    // eslint-disable-next-line
    const reg = new RegExp(/[`~!@#$%^&*()_\+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】、；‘'，。、]/im, 'g')
    if (value && reg.test(value)) {
      callback(new Error("输入框不能输入特殊字符"));
    } else {
      callback();
    }
  },
  //检测特殊字符
  special(rule, value, callback) {
    // eslint-disable-next-line
    const reg = new RegExp(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im, 'g')
    if (value && reg.test(value)) {
      callback(new Error("输入框不能输入特殊字符"));
    } else {
      callback();
    }
  },
};

// export default validator

export default validator;
