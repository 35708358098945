<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
        <span v-if="item.redirect === 'noRedirect'" class="no-redirect">{{
          item.meta.title
        }}</span>
        <span v-else-if="index === levelList.length - 1" class="last">
          {{ item.meta.title }}
        </span>
        <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      levelList: null,
    };
  },
  watch: {
    $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getBreadcrumb();
    },
  },
  computed: {
    ...mapGetters(["breadcrumbData"]),
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );
      const first = matched[0];
      if (!this.isDashboard(first)) {
        matched = [{ path: "/home", meta: { title: "首页" } }].concat(matched);
      }
      this.levelList = matched.filter(
        (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
      this.levelList.map((el, index) => {
        // console.log(el.meta.parentName);
        if (el.meta.parentName) {
          this.levelList.splice(index, 0, {
            path: el.meta.parentPath,
            meta: {
              title: el.meta.parentName,
            },
          });
        }
      });
      console.log("object :>> ", this.levelList);
    },
    isDashboard(route) {
      const name = route && route.name;
      // console.log("name :>> ", name);
      if (!name) {
        return false;
      }
      // return name.trim() === 'Index'
      return name.trim() === "home"; // 修复 Index 重复的问题
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  margin-left: 8px;
  color: #4e5969;
  .el-breadcrumb__inner {
    color: #4e5969 !important;
    a,
    .is-link {
      color: #4e5969 !important;
    }
  }
}
</style>
