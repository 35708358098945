<template>
  <div>
    <el-scrollbar wrap-class="scrollbar-wrapper" class="list-scrollbar">
      <el-menu
        :default-active="activeMenu"
        :unique-opened="true"
        :collapse-transition="false"
        mode="vertical"
      >
        <!-- 根据 sidebarRouters 路由，生成菜单 -->
        <sidebar-item
          v-for="(route, index) in sidebarRouters"
          :key="route.path + index"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import SidebarItem from "./SidebarItem";
import { constantRoutes } from "@/router";
export default {
  components: { SidebarItem },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (document.getElementsByClassName("iconfont").length > 0) {
        const iconfontList = document.getElementsByClassName("iconfont");
        for (let i = 0; i < iconfontList.length; i++) {
          if (iconfontList[i].getElementsByTagName("img").length > 0) {
            // 处理img元素
            iconfontList[i].getElementsByTagName("img")[0].src =
              this.sidebarRouters[i].meta.icon;
          }
        }
      }

      if (document.getElementsByClassName("is-active").length > 0) {
        const active = document
          .getElementsByClassName("is-active")[0]
          .getElementsByTagName("img")[0];
        if (meta.activeicon) {
          active.src = meta.activeicon;
        } else {
          this.sidebarRouters.map((el) => {
            if (el.name === meta.parent) {
              console.log("el :>> ", el);
              active.src = el.meta.activeicon;
            }
          });
        }
      }

      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    sidebarRouters() {
      let routers = JSON.parse(
        JSON.stringify(constantRoutes.filter((el) => el.children)[0].children)
      );
      return routers;
    },
  },
};
</script>
<style lang="scss"></style>
